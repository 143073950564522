import Texts from '../../Texts';
import './Contact.css'
import Pin from '../Common/Icons/pin1.svg';
import Email from '../Common/Icons/email1.svg';
import Phone from '../Common/Icons/phone1.svg';

function Contact() {
    return (
        <section id="contact">
            <div className="section-content full">
                <h1 className='centered'>{Texts.Contact.title}</h1>
                <address id='contact-data'>
                    <div>
                        <img alt='Ikona pinezki na mapie' src={Pin} />
                        {Texts.Contact.address[0]} <br/> {Texts.Contact.address[1]}
                    </div>

                    <div>
                        <a href={'tel:' + Texts.Data.phone}>
                            <img alt='Ikona słuchawki telefonu' src={Phone} />
                            {Texts.Data.phone}
                        </a>
                    </div>

                    <div>
                        <a href={'mailto:' + Texts.Data.email}>
                            <img alt='Ikona koperty na listy' src={Email} />
                            {Texts.Data.email}
                        </a>                        
                    </div>
                </address>
            </div>
        </section>
    );
  }
  
  export default Contact;
  