import './Header.css'
import House from '../Content/Common/house.svg'

function Header() {
    return (
        <div id="header">
            <img alt='Logo przedstawiające kontur domu' src={House} />
            <div className="logo noselect">
                <div className='top'>Elżbieta Hupało</div>
                <div className='bottom'>Kredyt Hipoteczny Wrocław</div>
            </div>
        </div>
    );
  }
  
  export default Header;
  