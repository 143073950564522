const Texts = {

/* -------------------------------------------------------------------------- */
/*                                DATA                                        */
/* -------------------------------------------------------------------------- */ 

    Data: {
        email: "elzbieta.hupalo@wp.pl",
        phone: "+48 600 822 368",
        phone_short: "600 822 368"
    },

/* -------------------------------------------------------------------------- */
/*                                WELCOME                                     */
/* -------------------------------------------------------------------------- */

    Welcome: {
        paragraphs: [
            "Szukasz nowego mieszkania?",
            "Potrzebujesz środków na spełnianie swoich planów?",
            "Oczekujesz kompleksowej pomocy doświadczonej osoby?",
            "Pomogę Ci w uzyskaniu kredytu!"
        ]
    },

/* -------------------------------------------------------------------------- */
/*                                SERVICES                                    */
/* -------------------------------------------------------------------------- */

    Services: {
        title: "Oferta",
        paragraphs: [
            "Zawsze trzeba od czegoś zacząć. Jeśli planujesz budowę domu, zakup mieszkania lub innej nieruchomości finansowanej kredytem, najlepiej zacząć od wyboru doświadczonego i godnego zaufania pośrednika kredytowego. Gdy powiedzie Ci się ten krok, możesz być pewien, że każdy kolejny będzie prostszy.",
            "Nazywam się Elżbieta Hupało i jestem ekspertem kredytowym. Oferuję bezpłatną pomoc w uzyskaniu kredytów dla osób fizycznych i firm. Współpracuję z kilkunastoma bankami, dzięki czemu mogę porównać warunki i wybrać dla Ciebie najkorzystniejszą ofertę. Decydując się na współpracę ze mną, możesz być pewien, że przedstawię Ci realne możliwości finansowe, nie będę narażać Cię na niepotrzebne koszty, nie wprowadzę Cię w błąd i nie będę Ci obiecywać rzeczy niemożliwych.",
        ],
        lists: [
            {
                header: "Kredyty dla osób fizycznych",
                bullets: [
                    "kredyty hipoteczne",
                    "kredyty gotówkowe",
                    "kredyty gotówkowe dla osób pracujących za granicą",
                    "refinansowanie zakupu wcześniej nabytej nieruchomości",
                    "pożyczki hipoteczne na dowolny cel",
                    "konsolidacja kilku kredytów w jeden"
                ]
            },
            {
                header: "Kredyty dla firm",
                bullets: [
                    "kredyty hipoteczne",
                    "kredyty inwestycyjne",
                    "kredyty obrotowe na finansowanie bieżącej działalności firmy",
                    "kredyty ze wsparciem unijnym",
                    "factoring",
                    "leasing"
                ]
            }
        ],
        header: "Krok po kroku",
        description: "Proces uzyskania kredytu to nie tylko składanie wniosku i oczekiwanie na decyzję. To również szereg wyborów i decyzji, które mogą mieć wpływ na to, czy kredyt zostanie przyznany, jakie będą jego warunki i jakie będą koszty. Pomogę Ci przejść przez ten proces, tak aby uzyskać kredyt na najlepszych warunkach. Przygotuję niezbędne dokumenty i wyjaśnię wszystkie niejasności. Będę Twoim przewodnikiem przez cały proces kredytowy, od składania wniosku po otrzymanie pieniędzy. Dzięki moim usługom będziesz miał pewność, że uzyskasz kredyt na najlepszych warunkach i bez stresu.",
        steps: [
            {
                number: "1",
                title: "Rozmowa telefoniczna",
                description: "Podczas pierwszego kontaktu omówimy Twoją sytuację finansową, cele i możliwości."
            },
            {
                number: "2",
                title: "Spotkanie",
                description: "Na podstawie Twojej zdolności kredytowej przedstawię dostępne produkty i pomogę Ci wybrać najkorzystniejsze dla Ciebie oferty."
            },
            {
                number: "3",
                title: "Złożenie wniosków",
                description: "Wspólnie wypełnimy wnioski do banków, których produkty będą najlepiej dostosowane do Twoich potrzeb."
            },
            {
                number: "4",
                title: "Analiza i decyzja kredytowa",
                description: "Banki przeprowadzają analizę finansową, prawną i techniczną. Na tej podstawie wydawana jest decyzja kredytowa. "
            },
            {
                number: "5",
                title: "Wybór najlepszej oferty",
                description: "Po uzyskaniu pozytywnych decyzji kredytowych pomogę Ci w negocjacjach cenowych i w wyborze najlepszej oferty. Wskażę różnice między dostępnymi wariantami."
            },
            {
                number: "6",
                title: "Podpisanie umowy i wypłata kredytu",
                description: "Nastepnym krokiem jest podpisanie umowy. Spotkamy się w oddziale wybranego banku i dopełnimy wszystkie formalności potrzebne do wypłaty kredytu."
            },
        ]
    },

/* -------------------------------------------------------------------------- */
/*                                About                                       */
/* -------------------------------------------------------------------------- */

    About: {
        title: "Dlaczego ja?",
        points: [
            {
                title: "Mam wieloletnie doświadczenie",
                description: "Od 2004 roku skutecznie pomagam klientom zaplanować przyszłość finansową. Świetnie znam rynek nieruchomości i posiadam licencję Pośrednika w Obrocie Nieruchomościami. Posiadam doświadczenie nabyte w wielu instytucjach finansowych, pracowałam między innymi dla Santander Bank Polska S.A., Nordea Bank Polska S.A., Raiffeisen Bank Polska S.A. Obecnie współpracuję z Expander Advisors Sp. z o.o. Dzięki mojej pracy szerokie grono (nie tylko wrocławskich) klientów pozyskało finansowe zaplecze na zakup i budowę mieszkań oraz domów."
            },
            {
                title: "Moje usługi są bezpłatne",
                description: "Nie ponosisz żadnych opłat za moje usługi, bo moja działalność jest finansowana przez banki. Kredyt udzielony za moim pośrednictwem nie będzie z tego tytułu droższy niż w banku."
            },
            {
                title: "Negocjuję",
                description: "Mam w ofercie kilkanaście banków. Dostęp do wielu ofert w jednym miejscu pozwala na uzyskanie najlepszych warunków dla Ciebie."
            },
            {
                title: "Załatwiam formalności",
                description: "Sprawdzam Twoją zdolność kredytową jeszcze przed złożeniem wniosków. Pomagam w skompletowaniu potrzebnych dokumentów i przeprowadzam przez cały proces kredytowy. Wszystko po to, żeby zaoszczędzić Twój czas i niepotrzebne nerwy."
            },
            {
                title: "Działam uczciwie i przejrzyście",
                description: "Nie należę do żadnej instytucji bankowej. Zawsze prezentuję plusy i minusy wybranej przez Ciebie oferty kredytowej. Rozmawiam też o ryzykach kredytu i pomagam się przed nimi zabezpieczyć. Dlatego właśnie moi Klienci mogą czuć się bezpiecznie na każdym etapie procesu kredytowego."
            },
            {
                title: "Porównuję oferty",
                description: "Jeśli masz już ofertę kredytu, możesz mi ją pokazać, a ja sprawdzę, czy są dostępne leszpe opcje. Pamiętaj, że oferty banków szybko się zmieniają i często pojawiają się nowe promocje."
            },
            {
                title: "Obsługuję kompleksowo",
                description: "Jeśli nie wiesz od czego zacząć, nie martw się! Obsłużę Cię kompleksowo, odpowiem na wszystkie pytania i wyjaśnię wszystkie wątpliwości."
            }
        ]
    },

/* -------------------------------------------------------------------------- */
/*                                Campaign                                    */
/* -------------------------------------------------------------------------- */
    Campaign: {
        title: "Bezpieczny Kredyt 2%",
        paragraphs: [
            "Rada Ministrów przyjęła projekt ustawy wprowadzającej program Pierwsze Mieszkaniem, który zawiera Bezpieczny Kredyt 2% oraz Konto Mieszkaniowe. Start programu jest zaplanowany na 1 lipca 2023 roku i będzie trwał do końca 2027 roku.",
            "Program Bezpieczny Kredyt 2% będzie polegał na dopłacie Skarbu Państwa obniżającej ratę kredytu. Rata będzie obniżona w takim stopniu, jakby oprocentowanie wynosiło 2% plus marża banku. Dopłata do rat będzie przysługiwała przez 10 lat dla kredytu spłacanego w formule rat malejących."
        ],
        header: "Często zadawane pytania",
        faqs: [
            {
                title: "Kto będzie mógł skorzystać z kredytu na 2%?",
                content: "Z programu będzie mogła skorzystać osoba nabywająca pierwszą w życiu nieruchomość, tj. mieszkanie, dom lub działkę budowlaną. Nabywca nie może mieć więcej niż 45 lat. Do programu mogą przystąpić single, małżeństwa oraz osoby wychowujące dzieci."
            },
            {
                title: "Jaka jest maksymalna kwota kredytu na 2%?",
                content: "Kwota kredytu nie może przekroczyć w przypadku singli 500 tys. zł, a w przypadku małżeństw oraz osób wychowujących dzieci 600 tys. zł. Wkład własny nie może być wyższy niż 200 tys. zł. W związku z tym maksymalna cena nieruchomości nie może przekroczyć 700 tys. zł dla singla oraz 800 tys. zł w posostałych przypadkach."
            },
            {
                title: "Jakie limity obowiązują przy wyborze nieruchomości?",
                content: "Nie ma limitu dotyczącego powierzchni ani ceny za metr kwadratowy nieruchomości nabywanej w ramach programu. Jednak maksymalna cena nieruchomości nie może przekroczyć 700 tys. zł dla singla oraz 800 tys. zł w przypadku małżeństw i osób wychowujących dzieci."
            },
            {
                title: "Jaką nieruchomość można nabyć w ramach programu?",
                content: "Z programu można skorzystać, nabywając nieruchomość zarówno na rynku pierwotnym, jak i wtórnym. Może to być kupno mieszkania, domu bądź działki budowlanej, a także budowa domu."
            }
        ]
    },

/* -------------------------------------------------------------------------- */
/*                                Contact                                     */
/* -------------------------------------------------------------------------- */

    Contact: {
        title: "Zapraszam do kontaktu",
        address: ["Strzegomska 2-4", "53-611 Wrocław"],
    }
}

export default Texts;