import '../Content.css'
import './ImageDivider.css'

function ImageDivider() {
    return (
        <section id="image-divider"/>
    );
  }
  
  export default ImageDivider;
  
  