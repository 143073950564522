import '../Content.css'
import Texts from '../../Texts';
import './Welcome.css'

function Welcome() {
    return (
        <section id="welcome">
            <div className="section-content">
                {Texts.Welcome.paragraphs.map((paragraph, index) => <h2 key={index}>{paragraph}</h2>)}
            </div>
        </section>
    );
  }
  
  export default Welcome;
  