import '../Content.css'
import NumberCircled from './NumberCircled';

function Tile(props) {
    return (
    <div className='tile'>
        <NumberCircled number={props.number} />
        <div className='title'>{props.title}</div>
        <div className='description'>{props.description}</div>
    </div>        
    );
  }
  
  export default Tile;