import './Navbar.css'
import { Link } from "react-scroll";
import ProgressBar from "react-scroll-progress-bar";
import Phone from '../Content/Common/Icons/phone.svg'
import Email from '../Content/Common/Icons/email.svg'
import Texts from '../Texts';

function Navbar() {
    return (
        <div id='navbar' className='noselect'>
            <div id='navbar-contact'>
                <a href={'tel:' + Texts.Data.phone}>
                    <img alt='Ikona słuchawki telefonu' src={Phone} />
                    {Texts.Data.phone_short}
                </a>
                <a href={'mailto:' + Texts.Data.email}>
                    <img alt='Ikona koperty na listy' src={Email} />
                    {Texts.Data.email}
                </a>
            </div>
            <div className='links'>
                <ProgressBar bgcolor="#f9f9f9"/>
                <Link
                    activeClass="active"
                    to="header"
                    smooth={true}>
                    Start
                </Link>
                <Link
                    activeClass="active"
                    to="services"
                    offset={-39}
                    smooth={true}>
                    Oferta
                </Link>
                <Link
                    activeClass="active"
                    to="about"
                    offset={-39}
                    smooth={true}>
                    O mnie
                </Link>
                {/* <Link
                    activeClass="active"
                    to="campaign"
                    offset={-39}
                    smooth={true}>
                    Kredyt 2%
                </Link> */}
                <Link
                    activeClass="active"
                    to="contact"
                    offset={-39}
                    smooth={true}>
                    Kontakt
                </Link>
            </div>
        </div>
    );
  }
  
  export default Navbar;
  