import './Map.css'

function Map() {
    return (
        <section id="map">
            <div className='section-content full'>
                <iframe
                    title="google-map"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2504.8005840885157!2d17.00260711596986!3d51.11213934734627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc206cad3b547%3A0xde130229773e279f!2sExpander!5e0!3m2!1spl!2spl!4v1669319393875!5m2!1spl!2spl">
                </iframe>
            </div>
        </section>
    );
  }
  
  export default Map;
  