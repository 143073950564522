import './NumberCircled.css'

function NumberCircled(props) {
    return (
        <div className='numberContainer'>
            {props.number}
        </div>
    );
  }
  
export default NumberCircled;