import './Footer.css'
import Texts from '../Texts';

function Footer() {
    return (
        <div id='footer'>
            <div className="logo">
                <div className='top'>Elżbieta Hupało</div>
                <div className='bottom'>Kredyt Hipoteczny Wrocław</div>
            </div>  
            <a href={'tel:' + Texts.Data.phone}>{Texts.Data.phone}</a>
            <a href={'mailto:' + Texts.Data.email}>{Texts.Data.email}</a>
        </div>
    );
  }
  
  export default Footer;
  