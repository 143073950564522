import './Services.css'
import Tile from './Tile';
import Texts from '../../Texts';

function Services() {
      return (
            <section id="services">
                  <div className="section-content">
                        <h1>{Texts.Services.title}</h1>
                        {Texts.Services.paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
                        <div id='products'>
                              {
                                    Texts.Services.lists.map((list, index) =>
                                          <div key={index}>
                                                <h2>{list.header}</h2>
                                                <div className='decorated-list'>
                                                      <div className='decor'/>
                                                      <ul>
                                                            {list.bullets.map((bullet, index) => <li key={index}>{bullet}</li>)}
                                                      </ul>
                                                </div>
                                          </div>
                                    )
                              }
                        </div>
                        <h2>{Texts.Services.header}</h2>
                        <p>{Texts.Services.description}</p>
                        <div className="tiles">
                              {
                                    Texts.Services.steps.map ((step, index) => 
                                          <Tile number={step.number}
                                                title={step.title}
                                                description={step.description}
                                                key={index} />    
                                    )
                              }
                        </div>
                  </div>
            </section>
      );
}

export default Services;
