import Welcome from './Welcome/Welcome';
import Services from './Services/Services';
import About from './About/About';
import Contact from './Contact/Contact';
import Map from './Map/Map';
// import Campaign from './Campaign/Campaign';
import ImageDivider from './ImageDivider/ImageDivider'

function Content() {
    return (
        <div id="Content">
            <Welcome />
            <Services />
            <About />
            {/* KREDYT 2% */}
            {/* <Campaign /> */}
            <ImageDivider />
            <Contact />
            <Map />
        </div>
    );
  }
  
  export default Content;
  