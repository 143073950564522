import Texts from '../../Texts';
import './About.css'

function About() {
    return (
        <section id="about">
            <div className="section-content">
                <h1>{Texts.About.title}</h1>

                {Texts.About.points.map((point, index) =>
                    <div key={index}>
                        <h2>{point.title}</h2>
                        <p>{point.description}</p>
                    </div>
                )}
            </div>
        </section>
    );
  }
  
  export default About;
  